<template>
  <v-row justify="center">
    <!-- หน้าจอธรรมดา -->
    <v-dialog
      v-if="resolutionScreen >= 500"
      v-model="shower"
      scrollable
      persistent
      max-width="500px"
    >
      <!-- <template v-slot:activator="{ on }">
        <v-btn color="primary" dark v-on="on">Open Dialog</v-btn>
      </template> -->

      <v-card>
        <v-card-title
          >{{ $t("changeName.changeName") }}<v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="35">create</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <br />
          <v-text-field
            :color="color.theme"
            prepend-icon="mdi-file-settings-outline"
            dense
            outlined
            :label="$t('changeName.filename')"
            v-model="filename"
            :disabled="createprogress"
            :error-messages="foldernameErrors"
            required
            @input="$v.filename.$touch()"
            @blur="$v.filename.$touch()"
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            outlined
            @click="$emit('close'), cleardata(), $emit('openDrag')"
            :disabled="createprogress"
            >{{ $t("checkpassword.cancel") }}</v-btn
          >
          <v-btn
            :color="color.theme"
            :dark="color.darkTheme"
            @click="checkfileshare()"
            :loading="createprogress"
          >
            {{ $t("changeName.submit") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- หน้าจอมือถือ -->
    <v-dialog
      persistent
      :max-width="maxWidthOnMobile"
      content-class="rounded-lg"
      v-else
      v-model="shower"
    >
      <v-card>
        <v-card-text class="pa-5">
          <v-layout class="pt-2 pb-4">
            <v-flex d-flex justify-center class="ml-8">
              <span :style="headerPage">{{ $t("changeName.changeName") }}</span>
            </v-flex>
            <v-flex xs1 d-flex justify-end>
              <v-icon
                :color="$vuetify.theme.dark ? 'white' : color.theme"
                @click="
                  $emit('close'), cleardata(), $emit('openDrag')
                "
                >mdi-close</v-icon
              >
            </v-flex>
          </v-layout>
          <span :style="titleText">{{ $t("changeName.filename") }}</span>
          <v-text-field
            class="mt-1 textFieldClass"
            :color="color.theme"
            outlined
            dense
            multiple
            v-model="filename"
            :disabled="createprogress"
            :error-messages="foldernameErrors"
            required
            @input="$v.filename.$touch()"
            @blur="$v.filename.$touch()"
          ></v-text-field>
        </v-card-text>
        <div class="text-center pb-4 mt-n2">
          <v-btn
            :style="btnAction"
            class="mr-6"
            height="40px"
            width="40%"
            color="red"
            outlined
            :disabled="createprogress"
            @click="$emit('close'), cleardata(), $emit('openDrag')"
            >{{ $t("checkpassword.cancel") }}</v-btn
          >
          <v-btn
            height="40px"
            width="40%"
            class="elevation-0"
            :style="btnAction"
            :color="color.theme"
            :dark="color.darkTheme"
            @click="checkfileshare()"
            :loading="createprogress"
            >{{ $t("checkpassword.ok") }}</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required, maxLength, email, helpers } from "vuelidate/lib/validators";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import checkCharacter from "@/globalFunctions/checkCharacter";

const SpecialCharacterForFoldernameValidator = helpers.regex("SpecialCharacterForFoldername", checkCharacter.checkSpecialCharacterForFoldername());
export default {
  mixins: [validationMixin],
  validations: {
    filename: { required, SpecialCharacterForFoldernameValidator },
  },
  props: ["show", "parentfolder", "filedata"],
  data: function() {
    return {
      filename: "",
      createprogress: false,
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
          let namewithoutextension = "";
          if (this.filedata.file_type !== "folder") {
            let filename = this.filedata.file_name.split(".");
            for (let i = 0; i < filename.length; i++) {
              if (i !== filename.length - 1) {
                namewithoutextension += filename[i];
              } else if (filename.length - 1 === 0) {
                namewithoutextension += filename[i];
              }
            }
          } else {
            namewithoutextension = this.filedata.file_name;
          }
          this.filename = namewithoutextension;
          this.$emit("closeDrag");
        }
        return this.show;
      },
      set(value) {
        if (!value) {
          this.$emit("closechangename");
        }
      },
    },
    foldernameErrors() {
      const errors = [];
      if (!this.$v.filename.$dirty) {
      } else if(!this.$v.filename.required) {
        errors.push(this.$t("changeName.addfilename"));
      } else if(this.$v.filename.$error) {
        errors.push(this.$t("changeName.nospecialcharacter"));
      }
      return errors;
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    // style อักษรใน dialog ของ mobile
    titleText() {
      return "color:black; font-size: 14px; line-height: 28px;";
    },
    // style อักษร header ของ mobile
    headerPage() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    // style ปุ่ม ของ mobile
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if(this.resolutionScreen >= 400) {
        return 600
      } else {
        return 346
      }
    },
  },
  methods: {
    async editnamefile() {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });

      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.createprogress = true;
        let api;
        let payload;
        let msgres;
        if (this.filedata.file_type === "folder") {
          payload = {
            data_id: this.filedata.file_id,
            data_type: "folder",
            account_id: this.dataAccountId,
            name: this.filename,
            //        "account_id": this.dataAccountId,
            //         "folder_id": this.filedata.file_id,
            //         "folder_name": this.filename,
            //         "status_folder": "",
            //         "move_to_id":"",
            //         "user_id": this.dataUsername
          };
          api =
            process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/change_name/data";
        } else {
          payload = {
            data_id: this.filedata.file_id,
            data_type: "file",
            account_id: this.dataAccountId,
            name: this.filename,
            // "account_id": this.dataAccountId,
            // "user_id": this.dataUsername,
            // "file_id": this.filedata.file_id,
            // "filename":this.filename,
            // "folder_id": "",
            // "status_file": ""
          };
          api =
            process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/change_name/data";
        }
        let auth = await gbfGenerate.generateToken();
        this.axios
          .post(api, payload, { headers: { Authorization: auth.code } })
          .then((response) => {
            console.log(response);
            if (response.data.status === "OK") {
              msgres = this.$t("changeName.changeSuccess");

              Toast.fire({
                icon: "success",
                title: msgres,
              });
              this.$emit("closechangename");
              this.$emit('opendragselect');
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
            this.createprogress = false;
          })
          .catch((error) => {
            console.log(error);
            Toast.fire({
              icon: "error",
              title: this.$t("changeName.noChange"),
              //title: "ไม่สามารถแก้ไขชื่อ"  + this.filedata.file_name + "ได้"
            });
            this.createprogress = false;
          });
      }
    },
    cleardata() {
      this.$v.$reset();
      this.foldername = "";
      this.createprogress = false;
    },
    checkfileshare() {
      // console.log(this.filedata);
      // console.log("dataac",this.dataAccountId);
      if (this.filedata.account_sender === this.dataAccountId) {
        this.editnamefile();
      } else {
        this.editnamesharefile();
      }
      console.log(this.filedata);
    },
    async editnamesharefile() {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.createprogress = true;
        let api;
        let payload;
        let msgres;
        if (this.filedata.file_type === "folder") {
          payload = {
            id: this.filedata.file_id,
            account_id: this.dataAccountId,
            new_name: this.filename,
          };
          api =
            process.env.VUE_APP_SERVICE_UPDATE_FOLDER +
            "/api/update_sharing_foldername_v4";
        } else {
          payload = {
            id: this.filedata.file_id,
            account_id: this.dataAccountId,
            new_name: this.filename,
          };
          api =
            process.env.VUE_APP_SERVICE_UPDATE_FILE +
            "/api/update_sharing_filename_v4";
        }
        console.log(payload);
        let auth = await gbfGenerate.generateToken();
        await this.axios
          .post(api, payload, { headers: { Authorization: auth.code } })
          .then((response) => {
            console.log(response);
            if (response.data.status === "OK") {
              msgres = this.$t("changeName.changeSuccess");

              Toast.fire({
                icon: "success",
                title: msgres,
              });
              this.$emit("closechangename");
              this.$emit('opendragselect');
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
            this.createprogress = false;
          })
          .catch((error) => {
            console.log(error);
            Toast.fire({
              icon: "error",
              title: "ไม่สามารถแก้ไขชื่อ" + this.filedata.file_name + "ได้",
            });
            this.createprogress = false;
          });
      }
    },
  },
};
</script>
<style>
.v-input.textFieldClass input {
  font-size: 14px;
}
</style>
